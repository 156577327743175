@import "react-day-picker/lib/style.css";

/* DayPicker Global */
.DayPickerInput {
    width: 100%;
    height: 100%;
}

.DayPicker-wrapper {
    padding-bottom: 15px;
}

.DayPicker-Month {
    height: min-content;
}
.DayPicker-Caption {
    @apply !text-center;
}

/* DayPicker Public */
.DayPickerPublic .DayPickerInput input {
    @apply relative flex justify-between w-full h-full p-20 cursor-pointer placeholder-grey-500 text-grey-800 focus:outline-none focus:ring focus:ring-grey-800 focus:ring-opacity-50 rounded-4;
}

@media screen and (max-width: 355px) {
    .DayPickerPublic .DayPickerInput-Overlay {
       position: relative;
    }
}

.DayPickerPublic.align-right .DayPickerInput-Overlay {
    right: 0px;
    left: initial;
}

.DayPickerPublic
    .DayPickerInput-OverlayWrapper
    .DayPickerInput-Overlay
    .DayPicker {
    color: coral;
    @apply px-0 py-30 sm:px-40 sm:py-30;
}

.DayPickerPublic .DayPicker-NavButton--next {
    background-image: url("/assets/icons/chevron-down.svg");
    height: 30px;
    width: 30px;
    border: #535353 1px solid;
    transform: rotate(270deg);
    border-radius: 100%;
    background-size: 12px 12px;
    @apply top-0 my-auto right-20 text-grey-800;
}

.DayPickerPublic .DayPicker-NavButton--prev {
    background-image: url("/assets/icons/chevron-down.svg");
    height: 30px;
    width: 30px;
    transform: rotate(90deg);
    border: #535353 1px solid;
    border-radius: 100%;
    background-position: center;
    background-size: 12px 12px;
    @apply left-20 top-[0px] text-grey-800 my-auto;
}

.DayPickerPublic .DayPicker-Caption > div {
    font-size: 16px !important;
    margin-top: 5px;
    @apply text-grey-800;
}

.DayPickerPublic .DayPicker-Months {
    flex-wrap: nowrap;
    width: min-content;
    gap: 60px;
}
.DayPickerPublic .DayPicker-Month {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
}
@media only screen and (max-width: 1500px) {
    .DayPickerPublic .DayPicker-Month:last-child {
        display: none;
    }
}

.DayPickerPublic .DayPicker-Day {
    height: 43px !important;
    max-height: 43px !important;
    min-width: 43px !important;
    @apply border border-[#EDE9E6]  rounded-[0px] p-0 font-sans font-normal text-14 text-grey-800;
}

@media screen and (max-width: 350px) {
    .DayPickerPublic .DayPicker-Day {
        min-height: 20px !important;
        min-width: 20px !important;
    }
}

.DayPickerPublic .DayPicker-Day:after {
    padding-bottom: 100% !important;
}

.DayPickerPublic .DayPicker-Day--outside {
    @apply text-transparent;
}

.DayPickerPublic .DayPicker-Day--disabled {
    text-decoration: line-through;
    @apply pointer-events-none text-grey-500;
}

/* Public Selected Days */
.DayPickerPublic.DayPicker-inline
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
    background: inherit !important;
}
.DayPickerPublic:not(.DayPicker-inline)
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside) {
    background: #474646 !important;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerPublic
    .DayPicker-Day--selected_start:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #535353;
    opacity: 0.1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerPublic .DayPicker-Day--selected_end:not(.DayPicker-Day--outside) {
    background: #474646 !important;
    opacity: 1 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

.DayPickerPublic
    .DayPicker-Day--selected_end:not(.DayPicker-Day--outside)::before {
    position: absolute;
    background: #535353;
    opacity: 0.1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerPublic
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside) {
    @apply !text-grey-850;
    position: relative;
    background: inherit;
    z-index: 1;
}

.DayPickerPublic
    .DayPicker-Day--selected_range:not(.DayPicker-Day--selected_start):not(.DayPicker-Day--selected_end):not(.DayPicker-Day--outside)::before {
    background: #f7f7f7 !important;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    content: "";
    z-index: -1;
}

.DayPickerPublic
    .DayPicker-Day--selected:not(.DayPicker-Day--outside):not(.DayPicker-Day--selected_range) {
    background: #474646 !important;
    z-index: 1;
    position: relative;
    text-align: center;
    font-size: 14px;
    color: white !important;
}

