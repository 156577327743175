@tailwind base;
@tailwind components;
@tailwind utilities;

@import "carousel.css";
@import "date_range.css";

:root {
  --container-padding: 20px;
}

body {
  @apply antialiased
}

@font-face {
  font-family: Baskerville;
  src: local("Baskerville Display PT Regular"),
    url("../../ui/fonts/BaskervilleDisplayPT-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: Baskerville;
  src: local("Baskerville Display PT Bold"),
    url("../../ui/fonts/BaskervilleDisplayPT-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Trebuchet MS";
  src: local("Trebuchet MS"),
    url("../../ui/fonts/Trebuchet-MS.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Trebuchet MS";
  src: local("Trebuchet MS Bold"),
    url("../../ui/fonts/Trebuchet-MS-Bold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

.container-public {
  @apply max-w-[1280px] mx-auto px-container-public;
}

.overlay {
  width: 100%;
  @apply left-0 z-50;
}

blockquote {
  @apply pl-20 border-l-3 border-primary border-opacity-30 text-grey-900 text-opacity-70;
}

.shadow-filters {
  background: linear-gradient(to left, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);
  pointer-events: none;
}

.overlay-shadow {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
}

/* This will only work if everything else in the parent element is center-aligned */
.overlay-w-full {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-headless-notifier-fixed {
  z-index: 9999;
}

.tf-v1-widget iframe {
  border-radius: 0px !important;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* Firefox */
}

#nprogress .bar {
  background: #A99058 !important;
}